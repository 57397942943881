import React from "react"

import pricingPdf from "../../assets/images/myfembree-pricing.pdf"

const HeaderDropdownData = [
  {
    name: "resources",
    list: [
      {
        listName: "Copay Assistance Program",
        listLink: "/commercial-insurance/",
        innerPageNav: false,
        externalLink: false,
        downloadLink: false,
        lineAfter: true,
      },
      {
        listName: "Provide e-Consent",
        listLink: "https://portal.trialcard.com/myovant/myfembree/consent/",
        innerPageNav: false,
        externalLink: true,
        downloadLink: false,
      },
      {
        listName: "Out-of-Pocket Cost",
        listLink: pricingPdf,
        innerPageNav: false,
        externalLink: false,
        downloadLink: true,
      },
    ],
  },
  {
    name: "test",
    list: [
      {
        listName: "Test Information",
        listLink: "",
      },
      {
        listName: "Test Support Program",
        listLink: "",
      },
    ],
  },
]

export default HeaderDropdownData
