import React, { useRef } from "react"
import { Container } from "react-bootstrap"
import { AppContext } from "../../contexts/state"
import CheckCircleTransparent from "../../assets/svgs/CheckCircleTransparent.svg"

const Cookie = () => {
  const slideTime = 600
  const slideTimeDesktop = 800
  const fadeTime = 300
  const slideUpTime = 300
  const sectionRef = useRef()
  const overlayRef = useRef()

  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }

  const showAccepted = contextFunction => {
    sectionRef.current.classList.add("cookie-section--fade")
    sectionRef.current.classList.add("cookie-section--slide")
    overlayRef.current.classList.add("cookie-section__overlay--accepted")

    const currentSlideTime = parseInt(
      getComputedStyle(sectionRef.current).getPropertyValue("--slide-time"),
      10
    )
    setTimeout(() => {
      contextFunction(true)
    }, fadeTime + currentSlideTime + slideUpTime)
  }

  return (
    <AppContext.Consumer>
      {context => {
        const { isCookieVisible, handleIsCookieVisible } = context
        return isCookieVisible ? (
          <section
            className="cookie-section"
            ref={sectionRef}
            style={{
              "--slide-time": `${slideTime}ms`,
              "--slide-time-desktop": `${slideTimeDesktop}ms`,
              "--slide-up-time": `${slideUpTime}ms`,
              "--fade-time": `${fadeTime}ms`,
              "--slide-fade-time": `${slideTime + fadeTime}ms`,
            }}
          >
            <Container className="cookie">
              <p className="cookie__copy">
                This website uses cookies to help us give you the best
                experience when you visit. By using this website, you consent to
                the use of these cookies. Find out more about how we use cookies
                and how to manage them by reading our{" "}
                <a
                  href="https://www.myovant.com/cookie-policy/"
                  className="cookie-link extra-bold"
                  target="_blank"
                >
                  Cookie Policy
                </a>
                .
              </p>
              <div className="cookie__actions">
                <button
                  className="cookie__accept"
                  onClick={() => showAccepted(handleIsCookieVisible)}
                >
                  ACCEPT
                </button>
                <button
                  className="cookie__decline"
                  onClick={() => handleIsCookieVisible(false)}
                >
                  Decline
                </button>
              </div>
            </Container>
            <div className="cookie-section__overlay" ref={overlayRef}>
              <span>COOKIES ACCEPTED!</span>
              <CheckCircleTransparent />
            </div>
          </section>
        ) : null
      }}
    </AppContext.Consumer>
  )
}

export default Cookie
