import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import IsiContent from "./IsiContent"

import plus from "../../assets/images/Plus.svg"
import minus from "../../assets/images/Minus.svg"
import plusHovered from "../../assets/images/plus-hovered.svg"
import minusHovered from "../../assets/images/minus-hovered.svg"

class ISI extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      yDirection: 0,
      isIsiExpanded: false,
      nonExpandedISI: false,
      hovered: false,
      isiMobileExpanded: "",
    }
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    this.handleScroll()
    window.addEventListener("resize", this.updateWindowDimensions)
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("scroll", () => this.toggleIsiHeader())
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
    window.removeEventListener("scroll", this.handleScroll)
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    })
  }

  handleScroll = event => {
    this.setState({
      yDirection: window.pageYOffset,
    })
    let element = document.querySelector(".isi-tray")
    if (this.state.yDirection < 1) {
      element.classList.remove("scroll")
    }
  }

  toggleIsiHeader = () => {
    const fadeInEffect = () => {
      let fadeEffect = setInterval(function() {
        if (fadeTarget.style.opacity) {
          fadeTarget.style.opacity = 1
          fadeTarget.style.zIndex = 100
        }
        if (fadeTarget.style.opacity < 0) {
          fadeTarget.style.opacity += 0.1
          fadeTarget.style.zIndex = 100
        } else {
          clearInterval(fadeEffect)
        }
      }, 50)
      this.setState({
        nonExpandedISI: false,
      })
    }

    const fadeOutEffect = () => {
      let fadeEffect = setInterval(function() {
        if (!fadeTarget.style.opacity) {
          fadeTarget.style.opacity = 1
        }
        if (fadeTarget.style.opacity > 0) {
          fadeTarget.style.opacity -= 0.1
          fadeTarget.style.zIndex = -1
        } else {
          clearInterval(fadeEffect)
        }
      }, 50)
      this.setState({
        nonExpandedISI: true,
      })
    }

    let fadeTarget =
      this.state.width > 991
        ? document.getElementById("isi-tray-desktop")
        : document.getElementById("isi-tray-mobile")

    let scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop
    let triggerElement = document
      .querySelector(".page-isi #trigger-tray")
      .getBoundingClientRect()
    let isiTrayHeight =
      this.state.width > 991
        ? document.getElementById("isi-tray-desktop").getBoundingClientRect()
            .height
        : document.getElementById("isi-tray-mobile").getBoundingClientRect()
            .height

    let trayHideHeight =
      triggerElement.top + scrollTop - window.innerHeight + isiTrayHeight
    let trayShowHeight =
      triggerElement.top + scrollTop - window.innerHeight + isiTrayHeight

    if (scrollTop >= trayHideHeight) {
      fadeOutEffect()
    } else if (scrollTop < trayShowHeight) {
      fadeInEffect()
    }
  }

  toggleIsiTray = () => {
    this.setState({
      isIsiExpanded: !this.state.isIsiExpanded,
    })
    // setIsiExpanded(!isIsiExpanded)
    let body = document.getElementsByTagName("body")[0]
    body.classList.toggle("isi-expanded")
  }

  handleHovered = val => {
    this.setState({
      hovered: val,
    })
  }

  handleIsiMobileExpanded = val => {
    if (val === this.state.isiMobileExpanded) {
      val = ""
    }
    this.setState(
      {
        isiMobileExpanded: val,
      },
      () => this.toggleIsiTray()
    )
  }

  render() {
    const {
      width,
      yDirection,
      isIsiExpanded,
      nonExpandedISI,
      hovered,
      isiMobileExpanded,
    } = this.state
    return (
      <>
        <section className="isi d-none d-lg-block">
          <div
            className={isIsiExpanded ? "isi-overlay" : ""}
            onClick={() => this.toggleIsiTray()}
          >
            <div
              className={
                isIsiExpanded
                  ? "isi-tray expanded"
                  : yDirection > 0
                  ? "isi-tray tray-collapsed"
                  : "isi-tray"
              }
              id="isi-tray-desktop"
            >
              <section className="isi-lower-tray-container">
                <Container>
                  <Row>
                    <Col lg={{ span: 12, offset: 0 }}>
                      <div className="isi-tray-container d-none d-lg-block">
                        <Row>
                          {!isIsiExpanded ? (
                            <>
                              <Col lg={11}></Col>
                            </>
                          ) : (
                            <Col lg={11} xs={11}></Col>
                          )}
                          <Col lg={1} xs={1}>
                            <div
                              className="isi-tray-icon-container"
                              onMouseEnter={() => this.handleHovered(true)}
                              onMouseLeave={() => this.handleHovered(false)}
                            >
                              {isIsiExpanded ? (
                                <img
                                  src={hovered ? minusHovered : minus}
                                  width="12"
                                  height="12"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={hovered ? plusHovered : plus}
                                  width="12"
                                  height="12"
                                  alt=""
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <div className="isi-content">
                          {isIsiExpanded ? (
                            <div className="isi-content-inner-container">
                              <IsiContent
                                isiMobileExpanded={isiMobileExpanded}
                                width={width}
                              />
                            </div>
                          ) : (
                            // isi tray not expanded >= 992px
                            <Row>
                              <h4 className="isi-title extra-isi-padding">
                                USE
                              </h4>
                              <p>
                                Myfembree is used to manage moderate to severe
                                pain associated with endometriosis in
                                premenopausal women ≥18 years of age. It should
                                not be taken for more than 24 months.
                              </p>
                              <h4 className="isi-title">
                                IMPORTANT SAFETY INFORMATION
                              </h4>
                              <p className="">
                                <span className="extra-bold purple">
                                  Boxed Warning.
                                </span>{" "}
                                <strong>Cardiovascular conditions:</strong>{" "}
                                Myfembree may increase your chances of heart
                                attack, stroke, or blood clots, especially if
                                you are >35 years old and smoke or have
                                uncontrolled high blood pressure.{" "}
                                <strong>
                                  Stop taking Myfembree and call your healthcare
                                  provider (HCP) or go to the nearest emergency
                                  room right away if you have:
                                </strong>{" "}
                                leg pain or swelling that won’t go away; sudden
                                shortness of breath; double vision, bulging of
                                the eyes, sudden partial or complete blindness;
                                pain or pressure in your chest, arm, or jaw;
                                sudden, severe headache unlike your usual
                                headaches; weakness or numbness in an arm or
                                leg, or trouble speaking.
                              </p>
                              <p className="extra-bold purple remove-isi-padding">
                                Do not take Myfembree if you:
                              </p>
                            </Row>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          </div>
        </section>

        {/* MOBILE ISI TRAY */}

        <section className="isi d-block d-lg-none">
          <div className={isiMobileExpanded !== "" ? "isi-overlay" : ""}>
            <div
              className={
                isiMobileExpanded !== ""
                  ? "isi-tray expanded"
                  : yDirection > 0
                  ? "isi-tray tray-collapsed"
                  : "isi-tray"
              }
              id="isi-tray-mobile"
              // onClick={() => typeof window !== 'undefined' && window.innerWidth > 0 ? this.toggleIsiTray() : null}
            >
              <section
                className={
                  isiMobileExpanded !== "" && yDirection > 0
                    ? "isi-lower-tray-container first-mobile-tray expanded"
                    : yDirection > 0
                    ? "isi-lower-tray-container first-mobile-tray tray-collapsed"
                    : "isi-lower-tray-container first-mobile-tray"
                }
              >
                <section className="isi-tray-btn-container-mobile">
                  <Container>
                    <Row
                      onClick={() => {
                        this.handleIsiMobileExpanded("use")
                        // this.toggleIsiTray();
                      }}
                    >
                      <Col lg={11} xs={11}></Col>
                      <Col lg={1} xs={1}>
                        <div
                          className="isi-tray-icon-container"
                          onMouseEnter={() => this.handleHovered(true)}
                          onMouseLeave={() => this.handleHovered(false)}
                        >
                          {isiMobileExpanded === "use" ? (
                            <img src={minus} width="12" height="12" alt="" />
                          ) : (
                            <img src={plus} width="12" height="12" alt="" />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
                <Container>
                  <Row>
                    <Col>
                      <div className="isi-tray-container d-block d-lg-none">
                        <div className="mobile-tray-1">
                          {isiMobileExpanded === "use" ? (
                            // isi tray expanded < 992px
                            <div className="isi-content-inner-container">
                              <h4 className="isi-title extra-isi-padding">
                                USE
                              </h4>
                              <p>
                                Myfembree is used to manage moderate to severe
                                pain associated with endometriosis in
                                premenopausal women ≥18 years of age. It should
                                not be taken for more than 24 months.
                              </p>
                              <h4 className="isi-title">
                                IMPORTANT SAFETY INFORMATION
                              </h4>
                              <p className="">
                                <span className="extra-bold purple">
                                  Boxed Warning.
                                </span>{" "}
                                <strong>Cardiovascular conditions:</strong>{" "}
                                Myfembree may increase your chances of heart
                                attack, stroke, or blood clots, especially if
                                you are >35 years old and smoke or have
                                uncontrolled high blood pressure.{" "}
                                <strong>
                                  Stop taking Myfembree and call your healthcare
                                  provider (HCP) or go to the nearest emergency
                                  room right away if you have:
                                </strong>{" "}
                                leg pain or swelling that won’t go away; sudden
                                shortness of breath; double vision, bulging of
                                the eyes, sudden partial or complete blindness;
                                pain or pressure in your chest, arm, or jaw;
                                sudden, severe headache unlike your usual
                                headaches; weakness or numbness in an arm or
                                leg, or trouble speaking.
                              </p>
                              <p className="extra-bold purple remove-isi-padding">
                                Do not take Myfembree if you:
                              </p>
                              <ul>
                                <li className="">
                                  have or have had blood clots in your legs,
                                  lungs, or eyes; a stroke or heart attack; a
                                  problem that makes your blood clot more than
                                  normal; blood circulation disorders; certain
                                  heart valve or rhythm problems that can cause
                                  blood clots to form in the heart; high blood
                                  pressure not well controlled by medicine;
                                  diabetes with kidney, eye, nerve, or blood
                                  vessel damage; certain kinds of severe
                                  migraine headaches with aura, numbness,
                                  weakness or changes in vision or migraine
                                  headaches if you are >35 years old; breast
                                  cancer or any cancer that is sensitive to
                                  female hormones; osteoporosis; undiagnosed
                                  vaginal bleeding; liver problems;
                                </li>
                                <li className="">
                                  smoke and are >35 years old;
                                </li>
                                <li className="">
                                  have had a serious allergic reaction (e.g.,
                                  swelling of your face, lips, mouth or tongue,
                                  trouble breathing, skin rashes, redness) or
                                  swelling or an allergic reaction to relugolix,
                                  estradiol, norethindrone or any of the
                                  ingredients in Myfembree.
                                </li>
                              </ul>
                              <p className="">
                                <span className="extra-bold purple">
                                  Bone loss (decreased bone mineral density
                                  [BMD]).
                                </span>{" "}
                                While taking Myfembree, your estrogen levels may
                                be low, which can lead to BMD loss. If this
                                happens, your BMD may improve after you stop
                                Myfembree, but complete recovery may not occur.
                                It is unknown if these BMD changes could
                                increase your risk for broken bones as you age.
                                For this reason,{" "}
                                <strong>
                                  you should not take Myfembree for more than 24
                                  months.
                                </strong>{" "}
                                Your HCP may order an X-ray test called a DXA
                                scan to check your BMD when you start Myfembree
                                and annually after.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Suicidal thoughts and behavior and worsening
                                  of mood.
                                </span>{" "}
                                <strong>
                                  Call your HCP or get emergency medical help
                                  right away if you have any of these symptoms,
                                  especially if they are new, worse, or bother
                                  you:
                                </strong>{" "}
                                thoughts about suicide or dying, attempts to
                                commit suicide, new or worse depression or
                                anxiety, other unusual changes in behavior or
                                mood. Pay attention to any changes, especially
                                sudden changes in your mood, behaviors,
                                thoughts, or feelings.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Abnormal liver tests.
                                </span>{" "}
                                <strong>
                                  Call your HCP right away if you have any of
                                  these signs and symptoms of liver problems:
                                </strong>{" "}
                                jaundice, dark, amber-colored urine, feeling
                                tired, nausea and vomiting, generalized
                                swelling, right upper stomach area pain,
                                bruising easily.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  High blood pressure.
                                </span>{" "}
                                See your HCP to check your blood pressure
                                regularly.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Effects on pregnancy.
                                </span>{" "}
                                <strong>Do not take</strong> Myfembree if you
                                are trying to become or are pregnant. It may
                                increase the risk of early pregnancy loss.{" "}
                                <strong>If you think you are pregnant</strong>,
                                stop taking Myfembree right away and call your
                                HCP. Myfembree can cause decreased or no
                                menstrual bleeding, making it hard to know if
                                you are pregnant. Watch for other signs of
                                pregnancy like breast tenderness, weight gain,
                                and nausea. Myfembree does not prevent
                                pregnancy. You will need to use effective
                                non-hormonal methods of birth control (e.g.,
                                condoms, spermicide) during and for 1 week after
                                stopping Myfembree. Do not take hormonal birth
                                control such as birth control pills, because
                                they may increase side effects and Myfembree may
                                not work as well.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Uterine fibroid prolapse or expulsion.
                                </span>{" "}
                                Fibroids can come out completely or partially
                                through the vagina. Call your HCP right away if
                                you have increased bleeding from the vagina,
                                which can be serious, or cramping.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Severe allergic reactions.
                                </span>{" "}
                                Myfembree may cause swelling of your face, lips,
                                mouth or tongue, trouble breathing, skin rashes,
                                and redness.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Most common side effects
                                </span>{" "}
                                are headache, hot flushes/sweating/night sweats,
                                mood changes including worsening depression,
                                abnormal vaginal bleeding, nausea, toothache,
                                back pain, decreased interest in sex, joint
                                pain, tiredness, and dizziness.
                              </p>
                              <p className="">
                                These are not all the possible side effects of
                                Myfembree. Call your doctor for medical advice
                                about side effects.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Tell your HCP about all
                                </span>{" "}
                                your prescription and over-the-counter
                                medicines, vitamins, and herbal supplements. If
                                you take oral P-gp inhibitors, take Myfembree
                                first and wait at least 6 hours before taking
                                the P-gp inhibitor. Ask your HCP if you are not
                                sure if you are taking this type of medicine.
                              </p>
                              <p className="">
                                <span className="extra-bold purple">
                                  Tell your HCP if you are breastfeeding.
                                </span>{" "}
                                Myfembree may pass into your breast milk.
                              </p>
                              <p className="extra-bold">
                                Please see full{" "}
                                <a
                                  href="https://www.myovant.com/myfembree-prescribing-information.pdf"
                                  target="_blank"
                                >
                                  Prescribing Information including BOXED
                                  WARNING
                                </a>{" "}
                                and{" "}
                                <a
                                  href="https://www.myovant.com/myfembree-patient-information.pdf"
                                  target="_blank"
                                >
                                  Patient Information
                                </a>
                              </p>
                            </div>
                          ) : isiMobileExpanded === "isi" ? (
                            <div></div>
                          ) : isiMobileExpanded !== "use" ? (
                            // isi tray unexpanded < 992px
                            <Row>
                              <Col lg={{ span: 5, offset: 0 }}>
                                <h4 className="isi-title">
                                  IMPORTANT SAFETY INFORMATION
                                </h4>
                                <p className="">
                                  <span className="extra-bold purple">
                                    Boxed Warning.
                                  </span>{" "}
                                  <strong>Cardiovascular conditions:</strong>{" "}
                                  Myfembree may increase your chances of heart
                                  attack, stroke, or blood clots, especially if
                                  you are >35 years old and smoke or have
                                  uncontrolled high blood pressure.{" "}
                                  <strong>
                                    Stop taking Myfembree and call your
                                    healthcare provider (HCP) or go to the
                                    nearest emergency room right away if you
                                    have:
                                  </strong>{" "}
                                  leg pain or swelling that won’t go away;
                                  sudden shortness of breath; double vision,
                                  bulging of the eyes, sudden partial or
                                  complete blindness; pain or pressure in your
                                  chest, arm, or jaw; sudden, severe headache
                                  unlike your usual headaches; weakness or
                                  numbness in an arm or leg, or trouble
                                  speaking.
                                </p>
                                <p className="extra-bold purple remove-isi-padding">
                                  Do not take Myfembree if you:
                                </p>
                              </Col>
                            </Row>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/*    <section className='isi-lower-tray-container second-mobile-tray'>*/}
              {/*      <section className='isi-tray-btn-container-mobile'>*/}
              {/*        <Container>*/}
              {/*          <Row*/}
              {/*            onClick={() => {*/}
              {/*              this.handleIsiMobileExpanded('isi');*/}
              {/*              // this.toggleIsiTray();*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <Col lg={11} xs={11}>*/}
              {/*              <h4 className='isi-title'>*/}
              {/*                IMPORTANT SAFETY INFORMATION*/}
              {/*              </h4>*/}
              {/*            </Col>*/}
              {/*            <Col lg={1} xs={1}>*/}
              {/*              <div*/}
              {/*                className='isi-tray-icon-container'*/}
              {/*                onMouseEnter={() => this.handleHovered(true)}*/}
              {/*                onMouseLeave={() => this.handleHovered(false)}*/}
              {/*              >*/}
              {/*                {*/}
              {/*                  isiMobileExpanded === 'isi' ? (*/}
              {/*                    <img*/}
              {/*                      src={minus}*/}
              {/*                      width='12'*/}
              {/*                      height='12'*/}
              {/*                      alt=''*/}
              {/*                    />*/}
              {/*                  ) : (*/}
              {/*                    <img*/}
              {/*                      src={plus}*/}
              {/*                      width='12'*/}
              {/*                      height='12'*/}
              {/*                      alt=''*/}
              {/*                    />*/}
              {/*                  )*/}
              {/*                }*/}
              {/*              </div>*/}
              {/*            </Col>*/}
              {/*          </Row>*/}
              {/*        </Container>*/}
              {/*      </section>*/}
              {/*      <Container>*/}
              {/*        <Row>*/}
              {/*          <Col>*/}
              {/*            <div className='isi-tray-container d-block d-lg-none'>*/}
              {/*            <div className='isi-content'>*/}
              {/*            {*/}
              {/*              isiMobileExpanded === 'isi' ?*/}
              {/*              <div className='isi-content-inner-container'>*/}
              {/*                <IsiContent*/}
              {/*                  isiMobileExpanded={isiMobileExpanded}*/}
              {/*                  width={width}*/}
              {/*                />*/}
              {/*              </div>*/}
              {/*              :*/}
              {/*              <Row>*/}
              {/*                <Col xs={12}>*/}
              {/*                  <p className='tray-text-padding'>*/}
              {/*                    <strong className='purple'>*/}
              {/*                      What is the most important information I should know about MYFEMBREE?*/}
              {/*                    </strong>*/}
              {/*                  </p>*/}
              {/*                  <p className='extra-bold tray-text-padding'>*/}
              {/*                    MYFEMBREE may cause serious side effects, including:*/}
              {/*                  </p>*/}
              {/*                  <ul className='purple-ul'>*/}
              {/*                    <li className='purple extra-bold'>*/}
              {/*                      cardiovascular conditions*/}
              {/*                    </li>*/}
              {/*                    <ul className='purple-ul-donut'>*/}
              {/*                      <li>*/}
              {/*                        MYFEMBREE may increase your chances of heart attack, stroke, or blood clots, especially if*/}
              {/*                        you are over 35 years of age, smoke, and have uncontrolled high blood pressure. <strong>Stop*/}
              {/*                        taking MYFEMBREE and call your healthcare provider right away or go to the nearest*/}
              {/*                        hospital emergency room right away if you have:</strong>*/}
              {/*                      </li>*/}
              {/*                      <ul className='purple-ul-square'>*/}
              {/*                        <li>*/}
              {/*                          leg pain or swelling that will not go away*/}
              {/*                        </li>*/}
              {/*                        <li>*/}
              {/*                          sudden shortness of breath*/}
              {/*                        </li>*/}
              {/*                        <li>*/}
              {/*                          double vision, bulging of the eyes, sudden blindness, partial or complete*/}
              {/*                        </li>*/}
              {/*                        <li>*/}
              {/*                          pain or pressure in your chest, arm, or jaw*/}
              {/*                        </li>*/}
              {/*                        <li>*/}
              {/*                          sudden, severe headache unlike your usual headaches*/}
              {/*                        </li>*/}
              {/*                        <li>*/}
              {/*                          weakness or numbness in an arm or leg, or trouble speaking*/}
              {/*                        </li>*/}
              {/*                      </ul>*/}
              {/*                    </ul>*/}
              {/*                  </ul>*/}
              {/*                  <ul className='purple-ul'>*/}
              {/*                    <li className='purple extra-bold'>*/}
              {/*                      bone loss (decreased bone mineral density)*/}
              {/*                    </li>*/}
              {/*                    <ul className='purple-ul-donut'>*/}
              {/*                      <li>*/}
              {/*                        While you are taking MYFEMBREE, your estrogen levels may be low. Low estrogen levels can*/}
              {/*                        lead to bone mineral density loss.*/}
              {/*                      </li>*/}
              {/*                      <li>*/}
              {/*                        If you have bone loss on MYFEMBREE, your bone density may improve after you stop taking*/}
              {/*                        MYFEMBREE, but complete recovery may not occur. It is unknown if these bone changes*/}
              {/*                        could increase your risk for broken bones as you age. For this reason, <strong>you should*/}
              {/*                        not take MYFEMBREE for more than 24 months.</strong>*/}
              {/*                      </li>*/}
              {/*                      <li>*/}
              {/*                        Your healthcare provider may order an X-ray test called a DXA scan to check your bone*/}
              {/*                        mineral density when you start taking MYFEMBREE and periodically after you start.*/}
              {/*                      </li>*/}
              {/*                      <li>*/}
              {/*                        Your healthcare provider may advise you to take vitamin D and/or calcium supplements as part*/}
              {/*                        of a healthy lifestyle that promotes bone health. If you are also advised to take iron*/}
              {/*                        supplements, they should be taken at least two hours apart from your vitamin D or calcium*/}
              {/*                        supplements.*/}
              {/*                      </li>*/}
              {/*                    </ul>*/}
              {/*                  </ul>*/}
              {/*                </Col>*/}
              {/*              </Row>*/}
              {/*            }*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </Col>*/}
              {/*    </Row>*/}
              {/*  </Container>*/}
              {/*</section>*/}
            </div>
          </div>
        </section>

        <section className="isi">
          <div id="page-isi" />
          <div id="isi" className="drive-to-container"></div>
          <div className="page-isi">
            <Container>
              <Row>
                <Col>
                  <IsiContent
                    isiMobileExpanded={isiMobileExpanded}
                    width={width}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </>
    )
  }
}

export default ISI
