import React, { useState, useEffect, useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AppContext } from "../../contexts/state"

import closeIcon from "../../assets/images/close.svg"
import closeIconHovered from "../../assets/images/close-hovered.svg"
import logo from "../../assets/images/popup-myfembree-logo.svg"

export default props => {
  const [hovered, handleHovered] = useState(false)
  const [yDirection, handleYDirection] = useState(0)
  const [xDirection, handleXDirection] = useState(0)
  const state = useContext(AppContext)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    window.removeEventListener("scroll", handleScroll)
    let modalContainer = document.getElementById("exit-container")
    if (state.isExitRampOpen === true) {
      document.body.classList.add("modal-hidden")
      modalContainer.classList.add("modal-overflow")
    } else {
      document.body.classList.remove("modal-hidden")
      modalContainer.classList.remove("modal-overflow")
    }
  })

  const handleScroll = () => {
    handleYDirection(window.pageYOffset)
    handleXDirection(window.innerWidth)
  }

  return (
    <>
      <section
        id="exit-container"
        style={
          xDirection < 426
            ? { top: `${yDirection - 650}px` }
            : xDirection < 769
            ? { top: `${yDirection - 450}px` }
            : { top: `${yDirection - 350}px` }
        }
        onClick={() => state.handleIsExitRampOpen()}
      ></section>
      <section className={state.isExitRampOpen ? "exit-popup-overlay" : ""}>
        <Container>
          <Row>
            <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
              <div
                className={
                  state.isExitRampOpen
                    ? "exit-popup-col"
                    : "exit-popup-col-hidden"
                }
              >
                <Row>
                  <Col lg={{ span: 1, offset: 11 }}>
                    <div
                      className="close-img-container"
                      onMouseEnter={() => handleHovered(true)}
                      onMouseLeave={() => handleHovered(false)}
                      onClick={() => state.handleIsExitRampOpen()}
                    >
                      <img
                        src={hovered ? closeIconHovered : closeIcon}
                        className="close-img"
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ span: 6, offset: 3 }}>
                    <div className="popup-logo-container">
                      <img
                        src={logo}
                        alt="Myfembree® (relugolix, estradiol, and norethindrone acetate) tablets 40 mg, 1 mg, 0.5 mg"
                        width="132"
                        height="105"
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="blue text-center">
                  You Are Now Leaving Myfembreeinfo.com
                </h3>
                <h6 className="text-center">
                  The information provided on myfembreehcp.com is intended only
                  for healthcare professionals in the United States.
                </h6>
                <h6 className="text-center">Would you like to continue?</h6>
                <Row>
                  <Col>
                    <div className="cta-btn-container exit-popup-btn-container">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.externalUrl}
                        onClick={() =>
                          state.handleIsExitRampOpen(state.externalUrl)
                        }
                      >
                        <button className="cta-btn pink-btn">Yes</button>
                      </a>
                    </div>
                    <div
                      className="cta-btn-container exit-popup-btn-container"
                      onClick={() => state.handleIsExitRampOpen()}
                    >
                      <button className="cta-btn transparent-btn-pink">
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
