import React from "react"
import ExternalIcon from "../../assets/svgs/ExternalIcon.svg"
import NonUSPopUp from "../../components/NonUSPopUp/NonUSPopUp"

class UtilityNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popup: false,
      yDirection: 0,
      xDirection: 0,
    }
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, { passive: true })
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      yDirection: window.pageYOffset,
      xDirection: window.innerWidth,
    })
  }

  handlePopUp = () => {
    if (document.getElementsByTagName("body")) {
      let modalContainer = document.getElementById("non-us-modal-container")
      {
        if (this.state.popup === false) {
          document.body.classList.add("modal-hidden")
          modalContainer.classList.add("modal-overflow")
        } else {
          document.body.classList.remove("modal-hidden")
          modalContainer.classList.remove("modal-overflow")
        }
      }
    }
    this.setState({
      popup: !this.state.popup,
    })
  }

  render() {
    const { xDirection, yDirection, popup } = this.state
    return (
      <>
        <NonUSPopUp handlePopUp={this.handlePopUp} popup={popup} />
        <section className="utility-nav">
          <nav className="utility-nav__container container">
            <ul className="utility-nav__list utility-nav-list">
              <li className="utility-nav-list__item utility-nav-list__item--no-mobile">
                <a href="#isi">
                  <span>IMPORTANT SAFETY INFORMATION</span>
                </a>
              </li>
              <li className="utility-nav-list__item">
                <a
                  href="https://www.myovant.com/myfembree-prescribing-information.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  <span>PRESCRIBING INFORMATION</span>
                  <ExternalIcon className="icon" />
                </a>
              </li>
              <li className="utility-nav-list__item">
                <a
                  href="https://www.myovant.com/myfembree-patient-information.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  <span>PATIENT INFORMATION</span>
                  <ExternalIcon className="icon" />
                </a>
              </li>
              <li className="utility-nav-list__item utility-nav-list__item--no-mobile">
                <a href="https://myfembreehcp.com/" target="_blank">
                  <span>FOR HEALTHCARE PROFESSIONALS</span>
                  <ExternalIcon className="icon" />
                </a>
              </li>
              <li className="utility-nav-list__item">
                <a href="https://www.myfembree.com/" target="_blank">
                  <span>EXPLORE OTHER INDICATION</span>
                  <ExternalIcon className="icon" />
                </a>
              </li>
            </ul>
          </nav>
        </section>
      </>
    )
  }
}

export default UtilityNav
