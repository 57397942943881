import React from "react"
import { AppContext } from "../../contexts/state"

import MainNav from "./MainNav"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNavOpen: false,
      popup: false,
    }
  }

  setNavOpen = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    })
  }

  handlePopUp = () => {
    if (document.getElementsByTagName("body")) {
      let modalContainer = document.getElementById("non-us-modal-container-2")
      {
        if (this.state.popup === false) {
          document.body.classList.add("modal-hidden")
          modalContainer.classList.add("modal-overflow")
        } else {
          document.body.classList.remove("modal-hidden")
          modalContainer.classList.remove("modal-overflow")
        }
      }
    }
    this.setState({
      popup: !this.state.popup,
    })
  }

  render() {
    const { isNavOpen, popup } = this.state
    return (
      <AppContext.Consumer>
        {context => {
          const {
            currentPage,
            handleCurrentPage,
            activeHeaderDropdown,
            handleActiveHeaderDropdown,
            activeDropdownLink,
            handleActiveDropdownLink,
          } = context
          return (
            <header
              className="main-header"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 9991,
                width: "100%",
              }}
            >
              <MainNav
                isNavOpen={isNavOpen}
                setNavOpen={this.setNavOpen}
                popup={popup}
                handlePopUp={this.handlePopUp}
                currentPage={currentPage}
                handleCurrentPage={handleCurrentPage}
                activeHeaderDropdown={activeHeaderDropdown}
                handleActiveHeaderDropdown={handleActiveHeaderDropdown}
                activeDropdownLink={activeDropdownLink}
                handleActiveDropdownLink={handleActiveDropdownLink}
              />
            </header>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default Header
