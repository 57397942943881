import React from "react"
import { Col, Row } from "react-bootstrap"

class IsiContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isiMobileExpanded, width } = this.props
    return (
      <section>
        <Row>
          <Col lg={{ span: 12 }}>
            {width < 992 && isiMobileExpanded === "isi" ? (
              <div></div>
            ) : (
              // isi expanded content >=992
              <div>
                <h4 className="isi-title extra-isi-padding">USE</h4>
                <p>
                  Myfembree is used to manage moderate to severe pain associated
                  with endometriosis in premenopausal women ≥18 years of age. It
                  should not be taken for more than 24 months.
                </p>
                <h4 className="isi-title">IMPORTANT SAFETY INFORMATION</h4>
              </div>
            )}
            {/*<div className="isi-content-inner-container">*/}
            <div className="">
              <p className="">
                <span className="extra-bold purple">Boxed Warning.</span>{" "}
                <strong>Cardiovascular conditions:</strong> Myfembree may
                increase your chances of heart attack, stroke, or blood clots,
                especially if you are >35 years old and smoke or have
                uncontrolled high blood pressure.{" "}
                <strong>
                  Stop taking Myfembree and call your healthcare provider (HCP)
                  or go to the nearest emergency room right away if you have:
                </strong>{" "}
                leg pain or swelling that won’t go away; sudden shortness of
                breath; double vision, bulging of the eyes, sudden partial or
                complete blindness; pain or pressure in your chest, arm, or jaw;
                sudden, severe headache unlike your usual headaches; weakness or
                numbness in an arm or leg, or trouble speaking.
              </p>
              <p className="extra-bold purple remove-isi-padding">
                Do not take Myfembree if you:
              </p>
              <ul id="trigger-tray">
                <li className="">
                  have or have had blood clots in your legs, lungs, or eyes; a
                  stroke or heart attack; a problem that makes your blood clot
                  more than normal; blood circulation disorders; certain heart
                  valve or rhythm problems that can cause blood clots to form in
                  the heart; high blood pressure not well controlled by
                  medicine; diabetes with kidney, eye, nerve, or blood vessel
                  damage; certain kinds of severe migraine headaches with aura,
                  numbness, weakness or changes in vision or migraine headaches
                  if you are >35 years old; breast cancer or any cancer that is
                  sensitive to female hormones; osteoporosis; undiagnosed
                  vaginal bleeding; liver problems;
                </li>
                <li className="">smoke and are >35 years old;</li>
                <li className="">
                  have had a serious allergic reaction (e.g., swelling of your
                  face, lips, mouth or tongue, trouble breathing, skin rashes,
                  redness) or swelling or an allergic reaction to relugolix,
                  estradiol, norethindrone or any of the ingredients in
                  Myfembree.
                </li>
              </ul>
              <p className="">
                <span className="extra-bold purple">
                  Bone loss (decreased bone mineral density [BMD]).
                </span>{" "}
                While taking Myfembree, your estrogen levels may be low, which
                can lead to BMD loss. If this happens, your BMD may improve
                after you stop Myfembree, but complete recovery may not occur.
                It is unknown if these BMD changes could increase your risk for
                broken bones as you age. For this reason,{" "}
                <strong>
                  you should not take Myfembree for more than 24 months.
                </strong>{" "}
                Your HCP may order an X-ray test called a DXA scan to check your
                BMD when you start Myfembree and annually after.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Suicidal thoughts and behavior and worsening of mood.
                </span>{" "}
                <strong>
                  Call your HCP or get emergency medical help right away if you
                  have any of these symptoms, especially if they are new, worse,
                  or bother you:
                </strong>{" "}
                thoughts about suicide or dying, attempts to commit suicide, new
                or worse depression or anxiety, other unusual changes in
                behavior or mood. Pay attention to any changes, especially
                sudden changes in your mood, behaviors, thoughts, or feelings.
              </p>
              <p className="">
                <span className="extra-bold purple">Abnormal liver tests.</span>{" "}
                <strong>
                  Call your HCP right away if you have any of these signs and
                  symptoms of liver problems:
                </strong>{" "}
                jaundice, dark, amber-colored urine, feeling tired, nausea and
                vomiting, generalized swelling, right upper stomach area pain,
                bruising easily.
              </p>
              <p className="">
                <span className="extra-bold purple">High blood pressure.</span>{" "}
                See your HCP to check your blood pressure regularly.
              </p>
              <p className="">
                <span className="extra-bold purple">Effects on pregnancy.</span>{" "}
                <strong>Do not take</strong> Myfembree if you are trying to
                become or are pregnant. It may increase the risk of early
                pregnancy loss. <strong>If you think you are pregnant</strong>,
                stop taking Myfembree right away and call your HCP. Myfembree
                can cause decreased or no menstrual bleeding, making it hard to
                know if you are pregnant. Watch for other signs of pregnancy
                like breast tenderness, weight gain, and nausea. Myfembree does
                not prevent pregnancy. You will need to use effective
                non-hormonal methods of birth control (e.g., condoms,
                spermicide) during and for 1 week after stopping Myfembree. Do
                not take hormonal birth control such as birth control pills,
                because they may increase side effects and Myfembree may not
                work as well.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Uterine fibroid prolapse or expulsion.
                </span>{" "}
                Fibroids can come out completely or partially through the
                vagina. Call your HCP right away if you have increased bleeding
                from the vagina, which can be serious, or cramping.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Severe allergic reactions.
                </span>{" "}
                Myfembree may cause swelling of your face, lips, mouth or
                tongue, trouble breathing, skin rashes, and redness.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Most common side effects
                </span>{" "}
                are headache, hot flushes/sweating/night sweats, mood changes
                including worsening depression, abnormal vaginal bleeding,
                nausea, toothache, back pain, decreased interest in sex, joint
                pain, tiredness, and dizziness.
              </p>
              <p className="">
                These are not all the possible side effects of Myfembree. Call
                your doctor for medical advice about side effects.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Tell your HCP about all
                </span>{" "}
                your prescription and over-the-counter medicines, vitamins, and
                herbal supplements. If you take oral P-gp inhibitors, take
                Myfembree first and wait at least 6 hours before taking the P-gp
                inhibitor. Ask your HCP if you are not sure if you are taking
                this type of medicine.
              </p>
              <p className="">
                <span className="extra-bold purple">
                  Tell your HCP if you are breastfeeding.
                </span>{" "}
                Myfembree may pass into your breast milk.
              </p>
              <p className="extra-bold">
                Please see full{" "}
                <span>
                  <a
                    href="https://www.myovant.com/myfembree-prescribing-information.pdf"
                    target="_blank"
                  >
                    Prescribing Information including BOXED WARNING
                  </a>
                </span>{" "}
                and{" "}
                <a
                  href="https://www.myovant.com/myfembree-patient-information.pdf"
                  target="_blank"
                >
                  Patient Information
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </section>
    )
  }
}

export default IsiContent
