import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
// import BackToTop from './backToTop'
import pfizerLogo from "../../assets/images/pfizer-footer-logo-white.svg"
import myovantLogo from "../../assets/images/myovant-footer-logo-white.svg"
import lineLogo from "../../assets/images/footer-logo-line-white.svg"

export default () => {
  const [hasMounted, setHasMounted] = React.useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return (
    <footer>
      <Container>
        <Row className="justify-content-between">
          <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <h6 className="footer-top-title">For US Residents Only</h6>
            <nav className="footer-nav-container">
              <ul>
                <li>
                  <a href="https://www.myovant.com/contact-us/" target="_blank">
                    Contact Us
                  </a>
                </li>
                <li className="footer-nav-separator">|</li>
                <li>
                  <a
                    href="https://www.myovant.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="footer-nav-separator">|</li>
                <li>
                  <a
                    href="https://www.myovant.com/terms-of-use/"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                </li>
                <li className="footer-nav-separator">|</li>
                <li>
                  <Link to="/sitemap/">Site Map</Link>
                </li>
              </ul>
            </nav>
            <h6 className="footer-text">
              Myfembree® and its associated logo are trademarks of Myovant
              Sciences GmbH. All other trademarks are the property of their
              respective owners.
            </h6>
            <h6>
              &copy;2022 Myovant Sciences GmbH and Pfizer Inc. <br />
              All rights reserved. PP-US-REL-CT-2200274 12/22
            </h6>
          </Col>
          <Col
            lg={{ span: "6", order: 2 }}
            xs={{ span: 12, order: 1 }}
            className="footer-topbar"
          >
            <p className="footer-span">
              You may report side effects of prescription drugs to the FDA.{" "}
              <br />
              Visit{" "}
              <a
                href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program"
                target="_blank"
                rel="noreferrer"
              >
                <u>http://www.fda.gov/medwatch</u>
              </a>{" "}
              or call{" "}
              <a href="tel:1-800-332-1088">
                <u>1-800-FDA-1088</u>
              </a>
              .
            </p>
            <div className="footer-logo-container">
              <a href="https://www.myovant.com" target="_blank">
                <img
                  src={myovantLogo}
                  alt="Myovant Sciences logo"
                  className="footer-logo"
                />
              </a>
              <img
                src={lineLogo}
                alt="Separator line"
                className="footer-logo-separator"
              />
              <a href="https://www.pfizer.com" target="_blank">
                <img
                  src={pfizerLogo}
                  alt="Pfizer logo"
                  className="footer-logo footer-logo-second"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
