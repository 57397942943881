import React from "react"
import DownloadIcon from "../../assets/svgs/DownloadIcon.svg"
import ExternalIcon from "../../assets/svgs/ExternalIcon.svg"
import { Col, Row } from "react-bootstrap"
import ExternalLink from "../exitramp/ExternalLink"

class HeaderDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      currentData,
      activeDropdownLink,
      handleActiveDropdownLink,
      handleActiveHeaderDropdown,
      currentPage,
      handleCurrentPage,
    } = this.props
    return (
      <Row>
        <Col lg={12}>
          <div
            className="header-dropdown-container"
            id="header-dropdown-container"
          >
            <ul className="header-dropdown-ul">
              {currentData.list.map((listData, i) => {
                const {
                  listName,
                  listLink,
                  innerPageNav,
                  externalLink,
                  downloadLink,
                  lineAfter,
                } = listData
                return (
                  <li
                    key={i}
                    className="header-dropdown-ul__item"
                    onClick={() => {
                      handleActiveDropdownLink(i + 1)
                      handleActiveHeaderDropdown("")
                    }}
                  >
                    {externalLink ? (
                      <ExternalLink
                        externalUrl={listLink}
                        className={
                          (downloadLink || externalLink || innerPageNav) &&
                          "with-icon"
                        }
                      >
                        {" "}
                        <span>{listName}</span>
                        {downloadLink && <DownloadIcon />}
                        {externalLink && <ExternalIcon />}
                        {innerPageNav && <span>inner</span>}
                      </ExternalLink>
                    ) : (
                      <a
                        className={
                          (downloadLink || externalLink || innerPageNav) &&
                          "with-icon"
                        }
                        target={
                          downloadLink || externalLink ? "_blank" : "_self"
                        }
                        href={listLink}
                      >
                        <span>{listName}</span>
                        {downloadLink && <DownloadIcon />}
                        {externalLink && <ExternalIcon />}
                        {innerPageNav && <span>inner</span>}
                      </a>
                    )}
                    {lineAfter && <hr />}
                  </li>
                )
              })}
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

export default HeaderDropdown
