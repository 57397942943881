import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

import ExternalLink from "../exitramp/ExternalLink.jsx"
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown"
import HeaderDropdownData from "../HeaderDropdown/HeaderDropdownData"
import brochure from "../../assets/images/Myfembree-Digital-Patient-Brochure.pdf"
import pricingPdf from "../../assets/images/myfembree-pricing.pdf"
import logo from "../../assets/images/header-logo.png"

import DiscussionGuide from "../../assets/images/MyFembree_Discussion_Guide_v10.pdf"
import DownloadIcon from "../../assets/svgs/Download2Icon.svg"
import ExternalIcon from "../../assets/svgs/ExternalIcon.svg"
import HandWave from "../../assets/svgs/HandWave.svg"
import InternalArrow from "../../assets/svgs/InternalArrow.svg"
import Phone from "../../assets/svgs/Phone.svg"
import ProgramIcon from "../../assets/svgs/ProgramIcon.svg"
import Question from "../../assets/svgs/Question.svg"
import Shield from "../../assets/svgs/Shield.svg"
import SideEffects from "../../assets/svgs/SideEffects.svg"
import Stats from "../../assets/svgs/Stats.svg"
import ThumbsUpIcon from "../../assets/svgs/ThumbsUpIcon.svg"
import Triangle from "../../assets/svgs/Triangle.svg"

class MainNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentData: { list: ["placeholder"] },
      isNavOpen: false,
      mobileDropDown1: 0,
      mobileDropDown2: 0,
    }
  }

  toggleNav = () => {
    this.props.setNavOpen()
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    })
    const utilityNav = document.getElementsByClassName("utility-nav")[0]
    const toggleButton = document.getElementsByClassName("navbar-toggle")[0]
    if (this.state.isNavOpen) {
      document.body.classList.remove("scroll-none")
      utilityNav.classList.remove("hidden")
      toggleButton.classList.remove("active")
    } else {
      setTimeout(() => {
        document.body.classList.add("scroll-none")
        utilityNav.classList.add("hidden")
        toggleButton.classList.add("active")
      }, 50)
    }
  }

  handleNavHover = () => {
    this.headerDropdownPromise()
      .then(currentData => this.headerDropdownPromise())
      .then(currentData => {
        this.handleDropdown(currentData, currentData.name)
      })
      .catch(err => console.log("There was an error:" + err))
  }

  headerDropdownPromise = () => {
    const { activeHeaderDropdown } = this.props
    let updatedData = { list: ["placeholder"] }

    return new Promise((resolve, reject) => {
      let dataFilter = HeaderDropdownData.filter((data, i) => {
        if (data.name === activeHeaderDropdown) {
          updatedData = data
        }
      })
      dataFilter ? resolve(updatedData) : reject("ERROR")
    })
  }

  handleDropdown = (val, name) => {
    let dropdownContainer = document.getElementById("header-dropdown-container")
    dropdownContainer.classList = `header-dropdown-container ${name}`
    this.setState({
      currentData: val,
    })
  }

  handleMobileDropDown = (key, val) => {
    this.setState({
      [key]: val,
    })
  }

  reset = () => {
    this.setState({
      mobileDropDown1: 0,
      mobileDropDown2: 0,
    })
    this.toggleNav()
  }

  render() {
    const {
      currentData,
      isNavOpen,
      mobileDropDown1,
      mobileDropDown2,
    } = this.state
    const {
      activeHeaderDropdown,
      handleActiveHeaderDropdown,
      activeDropdownLink,
      handleActiveDropdownLink,
      currentPage,
      handleCurrentPage,
    } = this.props
    return (
      <>
        <section
          className="main-nav"
          onMouseLeave={() => {
            handleActiveHeaderDropdown("")
            this.handleNavHover()
          }}
        >
          <Container>
            <Row>
              <Col lg={3} xs={7} className="navbar-header">
                <Link to="/">
                  <img
                    alt="Myfembree® (relugolix, estradiol, and norethindrone acetate) tablets 40 mg, 1 mg, 0.5 mg"
                    className="header-logo"
                    src={logo}
                    onMouseEnter={() => {
                      handleActiveHeaderDropdown("")
                      this.handleNavHover()
                    }}
                    width={"226"}
                    height={"92"}
                  />
                </Link>
              </Col>
              <Col xs={{ span: 3, offset: 2 }} className="d-block d-lg-none">
                <div className="mobile-navbar-container">
                  <button
                    type="button"
                    className="navbar-toggle"
                    onClick={() => {
                      this.toggleNav()
                    }}
                  >
                    <span className="sr-only">Toggle navigation</span>
                    {isNavOpen ? (
                      <>
                        {" "}
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </>
                    ) : (
                      <span className="menu-button">MENU</span>
                    )}
                  </button>
                </div>
              </Col>
              <Col
                xl={{ span: 2, offset: 7 }}
                lg={{ span: 3, offset: 6 }}
                xs={{ span: 6, offset: 1 }}
                className="d-none d-lg-block"
              >
                <div
                  className="header-call-now-container"
                  onMouseEnter={() => {
                    handleActiveHeaderDropdown("")
                    this.handleNavHover()
                  }}
                >
                  <h6>Questions?</h6>
                  <div className="cta-btn-container">
                    <a href="tel:1-833-693-3627">
                      <button className="cta-btn pink-btn">
                        1-833-693-3627
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="d-block d-menu">
          <Container>
            <Row>
              <Col xl={{ span: 12, offset: 0 }} className="d-none d-lg-block">
                <nav className="header-nav-container">
                  <ul>
                    <li>
                      <Link to={"/#what-is-endometriosis"}>
                        WHAT IS ENDOMETRIOSIS?
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#meet-myfembree"}>MEET MYFEMBREE</Link>
                    </li>
                    <li>
                      <Link to={"/#relieves-pain"}>
                        FOR 3 TYPES OF ENDO PAIN
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#side-effects"}>SIDE EFFECTS</Link>
                    </li>
                    <li
                      onMouseEnter={() => {
                        handleActiveHeaderDropdown("resources")
                        this.handleNavHover()
                      }}
                      onClick={() => {
                        handleActiveHeaderDropdown("")
                        handleActiveDropdownLink(0)
                      }}
                    >
                      <Link to={"/#cost-support"}>COST & SUPPORT</Link>
                      <svg
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeHeaderDropdown === "resources"
                            ? "nav-arrow"
                            : "nav-arrow rotated"
                        }
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.99984 3.95775e-05C6.13245 3.95775e-05 6.25962 0.0527177 6.35339 0.146486L10.9367 4.72982C11.132 4.92508 11.132 5.24167 10.9367 5.43693C10.7415 5.63219 10.4249 5.63219 10.2296 5.43693L5.99984 1.20715L1.77006 5.43693C1.5748 5.63219 1.25821 5.63219 1.06295 5.43693C0.867688 5.24167 0.867688 4.92508 1.06295 4.72982L5.64628 0.146486C5.74005 0.0527177 5.86723 3.95775e-05 5.99984 3.95775e-05Z"
                          fill="#A51890"
                        />
                      </svg>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
            <div
              className={
                activeHeaderDropdown === ""
                  ? "header-dropdown-hidden d-none d-lg-block"
                  : "header-dropdown d-none d-lg-block"
              }
              onMouseLeave={() => {
                handleActiveHeaderDropdown("")
                this.handleNavHover()
              }}
            >
              <HeaderDropdown
                currentData={currentData}
                activeDropdownLink={activeDropdownLink}
                handleActiveDropdownLink={handleActiveDropdownLink}
                handleActiveHeaderDropdown={handleActiveHeaderDropdown}
                currentPage={currentPage}
                handleCurrentPage={handleCurrentPage}
              />
            </div>
          </Container>
        </section>
        <section className={isNavOpen ? "mobile-nav opened" : "mobile-nav"}>
          <a
            className="mobile-nav__fixed-item mobile-nav__fixed-item--center mobile-nav__fixed-item--fuchsia"
            target="_blank"
            href="https://www.myfembree.com/"
          >
            <span className="text">EXPLORE OTHER INDICATION</span>
            <ExternalIcon className="icon" />
          </a>
          <ul className="mobile-nav__links">
            <li className="mobile-nav__jump-link mobile-nav__jump-link--no-link">
              Jump to&hellip;
            </li>
            <li className="mobile-nav__jump-link" onClick={this.reset}>
              <Link to={"/#what-is-endometriosis"}>
                <Question className="icon icon--what-is" />
                <span className="text">What Is Endometriosis?</span>
              </Link>
            </li>
            <li className="mobile-nav__jump-link" onClick={this.reset}>
              <Link to={"/#meet-myfembree"}>
                <HandWave className="icon icon--meet" />
                <span className="text">Meet Myfembree</span>
              </Link>
            </li>
            <li className="mobile-nav__jump-link" onClick={this.reset}>
              <Link to={"/#relieves-pain"}>
                <Stats className="icon icon--relieves" />
                <span className="text">For 3 Types of Endo Pain</span>
              </Link>
            </li>
            <li className="mobile-nav__jump-link" onClick={this.reset}>
              <Link to={"/#side-effects"}>
                <SideEffects className="icon icon--side-effects" />
                <span>Side Effects</span>
              </Link>
            </li>
          </ul>
          <ul className="mobile-nav__links mobile-nav__links--green">
            <li>
              <div
                className={`mobile-nav__dropdown-link-title ${
                  mobileDropDown1 === 1
                    ? "mobile-nav__dropdown-link-title--open"
                    : ""
                }`}
                onClick={() => {
                  if (mobileDropDown1 !== 1) {
                    this.handleMobileDropDown("mobileDropDown1", 1)
                    this.handleMobileDropDown("mobileDropDown2", 0)
                  } else {
                    this.handleMobileDropDown("mobileDropDown1", 0)
                  }
                }}
              >
                <span className="text">Cost & Support</span>
                <Triangle className="icon" />
              </div>
              <ul className="mobile-nav__dropdown-links mobile-nav__dropdown-links--cost-support">
                <li
                  onClick={this.reset}
                  className="mobile-nav__dropdown-link mobile-nav__dropdown-link--sage-050"
                >
                  <Link to="/commercial-insurance/">
                    <ProgramIcon className="icon" />
                    <span className="text">Copay Assistance Program</span>
                    <InternalArrow className="icon icon--large" />
                  </Link>
                </li>
                <li
                  onClick={this.reset}
                  className="mobile-nav__dropdown-link mobile-nav__dropdown-link--sage-025"
                >
                  <ExternalLink externalUrl="https://portal.trialcard.com/myovant/myfembree/consent/">
                    <ThumbsUpIcon className="icon" />
                    <span className="text">Provide e-Consent</span>
                    <InternalArrow className="icon icon--large icon--rotate" />
                  </ExternalLink>
                </li>
                <li
                  onClick={this.reset}
                  className="mobile-nav__dropdown-link mobile-nav__dropdown-link--sage-050"
                >
                  <a target="_blank" href={pricingPdf}>
                    <DownloadIcon className="icon" />
                    <span className="text">Out-of-Pocket Cost</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="mobile-nav__links mobile-nav__links--purple">
            <li>
              <div
                className={`mobile-nav__dropdown-link-title ${
                  mobileDropDown2 === 1
                    ? "mobile-nav__dropdown-link-title--open"
                    : ""
                }`}
                onClick={() => {
                  if (mobileDropDown2 !== 1) {
                    this.handleMobileDropDown("mobileDropDown2", 1)
                    this.handleMobileDropDown("mobileDropDown1", 0)
                  } else {
                    this.handleMobileDropDown("mobileDropDown2", 0)
                  }
                }}
              >
                <span className="text">Resources</span>
                <Triangle className="icon" />
              </div>
              <ul className="mobile-nav__dropdown-links mobile-nav__dropdown-links--resources">
                <li
                  onClick={this.reset}
                  className="mobile-nav__dropdown-link mobile-nav__dropdown-link--purple-010"
                >
                  <a target="_blank" href={brochure}>
                    <DownloadIcon className="icon" />
                    <span className="text">Brochure</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <a
            className="mobile-nav__fixed-item mobile-nav__fixed-item--purple"
            href="/#isi"
            onClick={this.reset}
          >
            <u>Important Safety Information</u> &gt;
          </a>
          <a
            className="mobile-nav__fixed-item mobile-nav__fixed-item--purple"
            href="https://myfembreehcp.com/"
            onClick={this.reset}
          >
            <u>Visit the Healthcare Professional Site</u> &gt;
          </a>
          <div className="mobile-nav__call">
            <div className="title">Questions?</div>
            <a
              className="button"
              href="tel:1-833-693-3627"
              onClick={this.reset}
            >
              <button className="cta-btn pink-btn">
                <Phone className="icon" />
                <span className="text">1-833-693-3627</span>
              </button>
            </a>
          </div>
        </section>
      </>
    )
  }
}

export default MainNav
